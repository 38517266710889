<template>
  <LcBadge v-bind="config[status]">
    {{ $t(`myorders.status.scratchcard.${status}`) }}
  </LcBadge>
</template>

<script lang="ts" setup>
import { LcBadge, type LcIconName } from '@lottocom/frontend-components'

import { ScratchcardState } from '~/@types/generated/backend/graphql-schema-types'

type StatusBadge = {
  class: string
  icon: LcIconName
}

type MyOrdersStatusBadgeScratchcardProps = {
  count?: number
  status: ScratchcardState
}

const props = defineProps<MyOrdersStatusBadgeScratchcardProps>()

const config = computed<Record<ScratchcardState, StatusBadge>>(() => ({
  [ScratchcardState.Cancelled]: {
    class: 'bg-danger100',
    icon: 'orders-canceled',
  },
  [ScratchcardState.Purchased]: {
    class: 'bg-secondary300',
    icon: 'orders-waiting-for-purchase',
  },
  [ScratchcardState.InProgress]: {
    class: 'bg-secondary300',
    icon: 'orders-waiting-for-purchase',
  },
  [ScratchcardState.Revealed]: {
    class: 'secondary300',
    icon: 'bell',
  },
  [ScratchcardState.Scratched]: {
    class: 'bg-secondary200',
    count: props.count,
    icon: 'bell',
  },
}))
</script>
